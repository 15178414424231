import io from 'socket.io-client';
import $ from 'jquery';

console.log('user_verify min geladen xxx');

const socket = io.connect('https://cci.gat.international',{secure: true,    rejectUnauthorized: false});
const inputUser = document.getElementById('login-name');
const keyVal = {
    input: []
};

//Daten an Server Schicken
inputUser.addEventListener('keyup', event => {
    keyVal.input = inputUser.value;
    console.log('val ---> ', keyVal);
    socket.emit('checkUserCCI', keyVal);
});

// Daten vom Server empfangen
socket.on('dataHit', function(data) {
    console.log('dataHit auf den Client --> ', data);
    const inputPass = document.getElementById('login-pwd');
    
    if (data) {
        $(inputUser).removeClass('invalid').addClass('valid');
        $(inputPass).removeClass('invalid').addClass('valid');
        window.setTimeout(function() {
            $('#login-btn').removeClass('small').addClass('big');
            $('#response-login').html('<h4>Hallo ' + inputUser.value + ' ,bitte gib dein Passwort ein</h4>');
        }, 250);
    } else {
        if ($(inputPass).hasClass('valid')) {
            $('#login-btn').removeClass('big').addClass('small');
            window.setTimeout(function() {
                $(inputPass).removeClass('valid').addClass('invalid');
                $(inputUser).removeClass('valid').addClass('invalid');
                $('#response-login').html('<h4>Bitte loggen Sie sich ein.</h4>');
            }, 250);
        }
    }
});